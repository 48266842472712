// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const ctaAccount = 'CTAACC';

export const API_KEYS = {
  PAYPAL_CLIENT_ID_TEST: 'ATID4pMhXRXf8RGlt5drorRrPIDaTtPB0fVVDvKIcQ5FPvGyophM-X7UayLT21xIuCXN1CrZO0CpRfj4'
};

export const process = {
  LOG_USER_REGISTER: 'REGISTRO DE USUARIO',
  LOG_USER_LOGIN: 'INICIO DE SESIÓN DE USUARIO',
  LOG_CREATE_ADMIN: 'CREACIÓN DE ADMINISTRADOR',
  LOG_EDIT_ADMIN: 'ACTUALIZACIÓN DE ADMINISTRADOR',
  LOG_DELETE_ADMIN: 'ELIMINACIÓN DE ADMINISTRADOR',
  LOG_CREATE_USER: 'CREACIÓN DE USUARIO',
  LOG_EDIT_USER: 'ACTUALIZACIÓN DE USUARIO',
  LOG_DELETE_USER: 'ELIMINACIÓN DE USUARIO',
  LOG_CREATE_PARAM: 'CREACIÓN DE PARÁMETRO',
  LOG_EDIT_PARAM: 'ACTUALIZACIÓN DE PARÁMETRO',
  LOG_DELETE_PARAM: 'ELIMINACIÓN DE PARÁMETRO',
  LOG_CREATE_TRAN: 'CREACIÓN DE TRANSACCIÓN',
  LOG_EDIT_TRAN: 'ACTUALIZACIÓN DE TRANSACCIÓN',
  LOG_DELETE_TRAN: 'ELIMINACIÓN DE TRANSACCIÓN',
  LOG_CREATE_BUSINESS: 'CREACIÓN DE NEGOCIO',
  LOG_EDIT_BUSINESS: 'ACTUALIZACIÓN DE NEGOCIO',
  LOG_DELETE_BUSINESS: 'ELIMINACIÓN DE NEGOCIO',
  LOG_CREATE_PROD: 'CREACIÓN DE PRODUCTO',
  LOG_EDIT_PROD: 'ACTUALIZACIÓN DE PRODUCTO',
  LOG_DELETE_PROD: 'ELIMINACIÓN DE PRODUCTO'
};
export const genConst = {
  CONST_PRO_ADM: 1001,
  CONST_PRO_DEF: 2002,
  CONST_PRO_VIS: 3003,
  CONST_ADM_NOT: 'Administrador del Sistema',
  CONST_PRO_ADM_TXT: 'Administrador',
  CONST_PRO_STU_TXT: 'Miembro Red',
  CONST_PRO_VIS_TXT: 'Usuario',
  CONST_STA_CRE: 'C',
  CONST_STA_DEB: 'D',
  CONST_STA_ACT: 1,
  CONST_STA_INACT: 0,
  CONST_STATE_IN: 0,
  CONST_STATE_AC: 1,
  CONST_STATE_PN: 2,
  CONST_NOTIF_NL: 0,
  CONST_NOTIF_LE: 1,
  CONST_SUB_STATE_INACTIVE: 0,
  CONST_SUB_STATE_ACTIVE: 1,
  CONST_SUB_STATE_0: 0,
  CONST_SUB_S_I: 0,
  CONST_SUB_S_U: 1,
  CONST_SUB_S_A: 2,
  CONST_SUB_STATE_ACT_TEXT: 'ACTIVA',
  CONST_SUB_STATE_INA_TEXT: 'INACTIVA',
  CONST_STA_ACT_TXT: 'Activo',
  CONST_STA_INACT_TXT: 'Inactivo',
  CONST_EXT_IMAGE: '.jpg',
  CONST_EXT_PDF: '.pdf',
  CONST_EXT_VIDEO_MP4: '.mp4',
  CONST_EXT_VIDEO_AVI: '.avi',
  CONST_PRIMARY_COLOR: '#53338a',
  CONST_SECONDARY_COLOR: '#a8afda',
  CONST_THIRD_COLOR: '#04a604',
  CONST_CREATE_COLOR: '#53338a',
  CONST_VIEW_COLOR: '#a8afda',
  CONST_UPDATE_COLOR: '#53338a',
  CONST_DELETE_COLOR: '#ff6961',
  CONST_CANCEL_COLOR: '#a8afda',
  CONST_SUCCESS_COLOR: '#04a604',
  CONST_INFO_COLOR: '#828cc9',
  CONST_ERROR_COLOR: '#ff6961',
  CONST_WARNING_COLOR: '#fdfd96',
  CONST_APPBAR_SEARCH: '#f5f6f8',
  CONST_APPBAR: '#53338a',
  CONST_COLOR_W: '#FFF',
  CONST_MTD_1_LBL: 'Tarjeta Débito / Crédito',
  CONST_MTD_2_LBL: 'Depósito / Transferencia',
  CONST_MTD_3_LBL: 'De Una',
  CONST_MTD_4_LBL: 'PayPal',
  CONST_MTD_5_LBL: 'Mercado Pago',
  CONST_MTD_6_LBL: 'Otro',
  CONST_MTD_7_LBL: 'Otro',
  CONST_MTD_1_VAL: 1,
  CONST_MTD_2_VAL: 2,
  CONST_MTD_3_VAL: 3,
  CONST_MTD_4_VAL: 4,
  CONST_MTD_5_VAL: 5,
  CONST_MTD_6_VAL: 6,
  CONST_CTA_AHO_DES: 'CTA. AHORROS',
  CONST_CTA_AHO_VAL: 'CTAAHO',
  CONST_CTA_COR_DES: 'CTA. CORRIENTE',
  CONST_CTA_COR_VAL: 'CTACORR',
  CONST_MONTH_VALUE: 50,
  CONST_1_MONTH_VALUE: 0,
  CONST_YEAR_VALUE: 500,
  CONST_1_YEAR_VALUE: 0,
  CONST_3_MONTH_VALUE: 0,
  CONST_MONTH_DAYS: 31,
  CONST_3_MONTH_DAYS: 90,
  CONST_YEAR_DAYS: 365,
  CONST_MONTH_CHAR: 1,
  CONST_YEAR_CHAR: 2,
  CONST_MONTH_TXT: 'MENSUAL',
  CONST_YEAR_TXT: 'ANUAL',
  CONST_IVA: 1.12,
  CONST_IVA_VAL: 0.15,
  CONST_400: 400,
  CONST_200: 200,
  CONST_LVL1: 0.2,
  CONST_LVL2: 0.15,
  CONST_LVL3: 0.1,
  CONST_LVL4: 0.05,
  CONST_BEN_CAN: 0,
  CONST_BEN_PAI: 2,
  CONST_BEN_PEN: 1,
  CONST_MAX_BUSINESS: 3,
  CONST_DEL_TRAN_1: 'Eliminación de Transacción - Pagos',
  CONST_DEL_TRAN_2: 'Eliminación de Transacción - Beneficio Khuska'
};

export const Messages = {
  lastDay: 'Es tu último día de membresía, renueva tu subscripción!'
};
