export const titles = {
  require: 'Todos los campos marcados con * son obligatorios.!',
  title: 'Red de Negocios',
  successCreate: 'Usuario creado correctamente!.',
  successUpdate: 'Usuario actualizado correctamente!.',
  successDelete: 'Usuario inactivado correctamente!.',
  titleDeleteModal: 'Seguro que desea eliminar al usuario: ',
  titleSub: 'Activar Suscripción',
  titleSubModal: 'Seguro que desea activar una suscripción por un ',
  titleCreate: 'Crear Usuario',
  titleUpdate: 'Editar Usuario',
  titleDelete: 'Inactivar Usuario',
  titleNetwork: 'Red Negocios',
  labelCreate: 'Usuario creado',
  labelUpdate: 'Usuario actualizado',
  labelDelete: 'Usuario inactivado',
  generalError: 'Ups algo salio mal: ',
  generalAction: 'Acciones',
  menuCreate: 'Agregar Usuario',
  tableCell1: 'Nombre',
  tableCell2: 'Email',
  tableCellC: 'Código',
  tableCell3: 'Perfil',
  tableCell4: 'Estado',
  tableCellActions: 'Acciones',
  maxRecords: 'Registros máximos',
  noRecordsFound: 'No existen usuarios aún!',
  buttonCreate: 'Crear',
  buttonUpdate: 'Actualizar',
  buttonDelete: 'Inactivar',
  buttonSub: 'Activar',
  buttonCancel: 'Cancelar',
  loading: 'Cargando...',
  level1: 'Nivel 1',
  level2: 'Nivel 2',
  level3: 'Nivel 3',
  level4: 'Nivel 4',
  active: 'ACTIVO',
  noActive: 'INACTIVO',
  altImage: 'Avatar user'
};

export const inputLabels = {
  search: 'searchField',
  name: 'name',
  labelName: 'Nombre',
  lastName: 'lastName',
  labelLastName: 'Apellido',
  email: 'email',
  labelEmail: 'E-mail',
  profile: 'profile',
  labelProfile: 'Perfil',
  state: 'State',
  labelState: 'Estado',
  placeHolderSearch: 'Buscar por Nombre, Apellido, Código o Correo electrónico'
};
